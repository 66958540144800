import React, {useEffect, useState} from "react";
import queryString from "query-string";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Invoice.scss';
import PDFContainer from "../components/PDFContainer";
import {Col, Row} from "reactstrap";
import InvoiceTable from "../components/InvoiceTable";

export interface IHTCDocumentOptions {
	landscape?: boolean;
	logoURL?: string;
	title?: string;
	paymentTerm?: string;
	deposit?: string;
	receivedDepositLabel?: string;
	receivedDeposit?: string;
	numberLabel?: string;
	numberLabelValue?: string;
	dueDate?: string;
	date?: string;
	supplierName?: string;
	supplierAddress?: string;
	notes?: string;
	billToName?: string;
	billToAddress?: string;
	remittanceInfo?: string;
	purchaseOrderNumber?: string;
	shippingTerm?: string;
	shipByDate?: string;
	warningText?: string;
	buyerText?: string;
	total?: string;
	subTotal?: string;
	data?: IHTCDocumentDataRow[];
	waterMark?: string;
	shipToName?: string;
	shipToAddress?: string;
	shipToText?: string;
	referenceNumberLabel?: string;
	referenceNumberValue?: string;
	harborTermsLabel?: string;
	harborTermsValue?: string;
}

export interface IHTCDocumentDataRow {
	item?: string,
	qty?: string,
	rate?: string,
	amount?: string,
	description?: string;
}

const Invoice: React.FC = (props) => {

	const [counter, setCounter] = useState(0);
	const increment = () => {setTimeout(() => setCounter(counter + 1), 500)};

	useEffect(() => {}, [counter])

	// @ts-ignore
	if (!window.location.href) {
		increment();
		return <p>no content {counter} {window.location.href}</p>;
	}

	const url: URL = new URL(window.location.href);
	const params: URLSearchParams = url.searchParams;
	const info = params.get("info");
	const query = {info};

	// @ts-ignore
	// const query = queryString.parse(window.location.search, {ignoreQueryPrefix: true});

	if (!query || !query.info) {
		increment();
		return <p>no content {counter} {window.location.href} {info}</p>;
	}

	const preValues: IHTCDocumentOptions = {
		referenceNumberLabel: "Reference Number",
		harborTermsLabel: "Harbor Terms",
		...JSON.parse(query.info),
	};

	const {
		logoURL,
		title,
		paymentTerm,
		deposit,
		receivedDeposit,
		receivedDepositLabel,
		numberLabel,
		numberLabelValue,
		dueDate,
		date,
		supplierName,
		supplierAddress,
		notes,
		billToName,
		billToAddress,
		remittanceInfo,
		shippingTerm,
		shipByDate,
		buyerText,
		warningText,
		data,
		total,
		waterMark,
		subTotal,
		shipToName,
		shipToAddress,
		shipToText,
		referenceNumberLabel,
		referenceNumberValue,
		harborTermsLabel,
		harborTermsValue,
	}: IHTCDocumentOptions = preValues;

	// parse line breaks
	function plb(str: string): JSX.Element[] {
		const splitArr = str.split("\n");
		const eleArr: JSX.Element[] = [];
		for (let i = 0; i < splitArr.length; i++) {
			if (i !== 0) {
				eleArr.push(<br key={Math.random()}/>)
			}
			eleArr.push(
				<React.Fragment key={Math.random()}>
					{splitArr[i]}
				</React.Fragment>
			)
		}
		return eleArr
	}

	function createLabelValue(
		label: string,
		value: string,
		leftSize: number = 4,
		rightSize: number = 6,
		leftAlign: "text-left" | "text-right" = "text-left",
		rightAlign: "text-left" | "text-right" = "text-right",
	) {

		const space = 12 - rightSize - leftSize;

		return (
			<Row>
				<Col md={leftSize} className={leftAlign}>
					<b>{plb(label)}</b>
				</Col>
				{space > 0 && space < 12 && <Col md={space}/>}
				<Col md={rightSize} className={rightAlign}>{plb(value)}</Col>
			</Row>
		)
	}

	return (
		<PDFContainer waterMark={waterMark}>
			{
				// header
			}
			<Row>
				<Col md={6}>
					<div className="w-100 text-left">
						{logoURL &&
						<>
							<img
								className="logo"
								src={logoURL}
								alt="logo"
							/>
						</>
						}
					</div>
				</Col>
				<Col md={6}>
					<div className="w-100 text-right">
						{
							title &&
							<h1>{title}</h1>
						}

						{
							numberLabel && numberLabelValue &&
							<h3>{numberLabel}: {numberLabelValue}</h3>
						}
						{
							date &&
							<h3>{date}</h3>
						}
					</div>
				</Col>
			</Row>

			{
				// single variable section
			}
			<Row className="mb-3">
				<Col md={6}>
					<p>
						{supplierName &&
						<>
							<b>{supplierName}</b>
							<br/>
						</>
						}
						{supplierAddress &&
						plb(supplierAddress)
						}
					</p>
				</Col>
				<Col md={6}>
					{
						(referenceNumberLabel && referenceNumberValue) &&
						createLabelValue(referenceNumberLabel, referenceNumberValue, 6, 6)
					}
					{
						paymentTerm &&
						createLabelValue("Payment Term", paymentTerm)
					}
					{
						deposit &&
						createLabelValue("Deposit", deposit)
					}
					{
						shippingTerm &&
						createLabelValue("Shipping Term", shippingTerm)
					}
					{
						shipByDate &&
						createLabelValue("Ship By Date", shipByDate)
					}
				</Col>
			</Row>

			<Row>
				<Col md={6}>
					{/*{(supplierName || supplierAddress) && <br/>}*/}
					<p>
						{buyerText &&
						<>
							<b>{buyerText}</b>
							<br/>
						</>
						}
						{
							billToName &&
							<>
								<b>{billToName}</b>
								<br/>
							</>
						}
						{
							billToAddress &&
							<>
								{plb(billToAddress)}
								<br/>
							</>
						}
					</p>
				</Col>
					{/*{(shipToName || shipToAddress || shipToText) && <br/>}*/}
				<Col md={6}>
					{shipToText &&
					<>
						<b>{shipToText}</b>
						<br/>
					</>
					}
					{
						shipToName &&
						<>
							<b>{shipToName}</b>
							<br/>
						</>
					}
					{
						shipToAddress &&
						<>
							{plb(shipToAddress)}
							<br/>
						</>
					}
				</Col>
			</Row>
			{
				// warning
				warningText &&
					<Row>
						<Col md={12} className="text-center">
							<h4 className="text-danger">{warningText}</h4>
						</Col>
					</Row>
			}
			{
				// table
				data &&
				<>
					<br/>
					<InvoiceTable
						data={data}
						total={total}
						receivedDepositLabel={receivedDepositLabel}
						deposit={receivedDeposit}
						subTotal={subTotal}
					/>
					<br/>
				</>
			}

			{
				// footer area
			}

			{
				(harborTermsLabel && harborTermsValue) &&
				createLabelValue(harborTermsLabel, harborTermsValue, 2, 10, "text-left", "text-left")
			}
			{
				notes &&
				createLabelValue("Notes", notes, 2, 10, "text-left", "text-left")
			}
			{
				remittanceInfo &&
				createLabelValue("Remittance Info", remittanceInfo, 2, 10, "text-left", "text-left")
			}
		</PDFContainer>
	)
};

export default Invoice;
