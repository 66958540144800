import React, {ReactNode} from "react";
import "./PDFContainer.scss"

interface IProps {
	height?: any;
	width?: any;
	horizontal?: boolean;
	children?: ReactNode;
	waterMark?: string;
}

const PDFContainer: React.FC<IProps> = (props) => {

	const {height, width, horizontal, children, waterMark} = props;

	const w = horizontal ? height : width;
	const h = horizontal ? width : height;
	const padding = 70;

	return (
		<div style={{width: w, height: h, paddingLeft: padding, paddingRight: padding}}>
			{
				waterMark &&
				<div
					className="position-fixed"
					style={{
						height: h,
						width: `calc(${w} - ${padding}px)`,
						opacity: .25
					}}
				>
					<div className="h-100 align-items-center justify-content-center d-flex">
						<h1
							style={{
								transform: "rotate(30deg)",
								fontSize: "10em"
							}}
						>
							{waterMark}
						</h1>
					</div>
				</div>
			}
			<table className="colour-adjust" style={{width: "100%"}}>
				<thead>
				<tr>
					<td>
						<div className="header-space" style={{color: "transparent"}}>-</div>
						<div
							style={{
								position: "fixed",
								width: "8px",
								height: "200vh",
								left: "0px",
								top: 0,
							}}
						>

						</div>
					</td>
				</tr>
				</thead>
				<tbody className="colour-adjust">
				<tr>
					<td>
						<div className="content">{children}</div>
					</td>
				</tr>
				</tbody>
				<tfoot>
				<tr>
					<td>
						<div className="footer-space">&nbsp;</div>
					</td>
				</tr>
				</tfoot>
			</table>
		</div>
	)
};


PDFContainer.defaultProps = {
	horizontal: false,
	width: "100%",
	height: "100%",
};

export default PDFContainer;