import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Row, Table} from "reactstrap";
import {IHTCDocumentDataRow} from "../pages/Invoice";

interface IProps {
	data: IHTCDocumentDataRow[],
	total?: string,
	deposit?: string,
	subTotal?: string,
	receivedDepositLabel?: string,
}

const InvoiceTable: React.FC<IProps> = ({data, total, deposit, subTotal, receivedDepositLabel}) => {

	function createRow(d: IHTCDocumentDataRow, i: number) {
		return (
			<tr key={i}>
				<td scope="row" className="text-center">{i + 1}</td>
				<td className="text-left">
					<span className="font-weight-bold">{d.item}</span>
					<br/>
					<span>{d.description}</span>
				</td>
				<td className="text-right">{d.qty}</td>
				<td className="text-right">{d.rate}</td>
				<td className="text-right">{d.amount}</td>
			</tr>
		)
	}

	return (
		<>
			<Table>
				<thead className="thead-dark">
				<tr>
					<th className="text-center">#</th>
					<th className="text-left">Item & Description</th>
					<th className="text-right">Qty</th>
					<th className="text-right">Rate</th>
					<th className="text-right">Amount</th>
				</tr>
				</thead>
				<tbody>
				{data && data.map(createRow)}
				{subTotal &&
				<tr>
					<td className="text-right" colSpan={4}><b>Sub Total:</b></td>
					<td colSpan={1} className="text-right">{subTotal}</td>
				</tr>
				}
				{deposit &&
				<tr style={subTotal ? {borderStyle: "hidden"} : {}}>
					<td className="text-right" colSpan={4}><b>{receivedDepositLabel ? receivedDepositLabel : "Received Deposit" }</b></td>
					<td colSpan={1} className="text-right">{deposit}</td>
				</tr>
				}
				{
					total &&
					<tr style={deposit || subTotal ? {borderStyle: "hidden"} : {}}>
						<td className="text-right" colSpan={4}><b>Total:</b></td>
						<td colSpan={1} className="text-right">{total}</td>
					</tr>
				}
				</tbody>
			</Table>

		</>
	)
};

export default InvoiceTable;
