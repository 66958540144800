import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,
	ButtonGroup,
	Card,
	CardBody,
	CardHeader,
	CardText,
	Container,
	FormGroup, FormText,
	Input,
	Label, UncontrolledCollapse
} from "reactstrap";
import {IHTCDocumentDataRow, IHTCDocumentOptions} from "./Invoice";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import {Link} from "react-router-dom";
import ReactMarkdown from "react-markdown";

interface IButtonOptions {
	displayText: string;
	documentOptions: IHTCDocumentOptions;
}

const date = moment().format("DD MMM YYYY");
const dueDate = moment().add(1, "month").format("DD mmm YYYY");
const remittanceInfo = "Beneficiary: Harbor Trade Credit\nBank Name and Address\nReally Good Bank N.A." +
	"789 Lotsa Money Ave, San Francisco, CA 94104\n\nABA/Routing Number: " + Math.floor(Math.random() * 1000000000).toString() +
	"\nAccount Number: " + Math.floor(Math.random() * 100000000000000000).toString() +
	"\n\nThis invoice is subject to the Harbor Terms & Conditions of Sale Agreement and terms therein\nTax ID: CR 2093997 \nAll invoices are in USD";
const numberLabelValue = Math.floor(Math.random() * 1000000).toString();
const billToName = "Frame One Software";
const billToAddress = "123 Fake Street\nDelta, BC V4K2A2\nCanada";
const supplierName = "Harbor Trade Credit";
const supplierAddress = "456 Cool Guy Avenue\nBeverly Hills, CA 90210\nUnited States";
const shippingTerm = "FOB";
const notes = "This is a test invoice, nothing on here is real and is all mock data.";
const shipByDate = moment().add(1, "week").format("DD MMM YYYY");
const deposit = "$10,000.00";
const receivedDeposit = "$5,000.00";
const [data, total, subTotal] = createData(5);
const [lotsOfData, lotsOdDataTotal, lotsOfDataSubTotal] = createData(1000);
const receivedDepositLabel = "Received Deposit";
const shipToName = "Gray Powroznik";
const shipToAddress = "890 Vim Avenue\nDenver, WY 80210\nUnited States";
const shipToText = "Ship To";
const referenceNumberLabel = "Reference Number";
const referenceNumberValue = "12345";
const harborTermsLabel = "Harbor Terms";
const harborTermsValue = "Net 90";

function numberWithCommas(x: string): string {
	const parts = x.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
}

function createData(rows: number): [IHTCDocumentDataRow[], string, string] {
	const data: IHTCDocumentDataRow[] = [];
	let total: number = 0;
	for (let i = 0; i < rows; i ++) {
		const qty = (Math.ceil(Math.random() * 10)).toFixed(4);
		const rate = (Math.ceil(Math.random() * 1000)).toFixed(2);
		total += Number(qty) * Number(rate);
		data.push({
			item: "Really Good Software",
			qty: numberWithCommas(qty),
			rate: numberWithCommas(rate),
			amount: numberWithCommas((Number(qty) * Number(rate)).toFixed(2)),
			description: "This is a description of the item.",
		})
	}
	total += 5000; // for deposit
	const subTotal = total - 5000;
	return [data, "$"+ numberWithCommas(total.toFixed(2)), "$"+ numberWithCommas(subTotal.toFixed(2))];
}

const buttons: IButtonOptions[] = [
	{
		displayText: "Sample Invoice",
		documentOptions: {
			logoURL: process.env.PUBLIC_URL + "/harbor-trade-credit-logo.png",
			title: "Invoice",
			date,
			paymentTerm: "NET 120",
			dueDate,
			numberLabel: "Invoice",
			numberLabelValue,
			remittanceInfo,
			supplierName,
			supplierAddress,
			billToName,
			billToAddress,
			notes,
			shippingTerm,
			buyerText: "Bill to",
			data,
			total,
			subTotal,
			deposit,
			receivedDeposit,
			shipToName,
			shipToAddress,
			shipToText,
			receivedDepositLabel,
			// referenceNumberLabel,
			referenceNumberValue,
			// harborTermsLabel,
			harborTermsValue,
		}
	},
	{
		displayText: "Sample Purchase Order",
		documentOptions: {
			logoURL: process.env.PUBLIC_URL + "/frame-one-software-logo.png",
			title: "Purchase Order",
			date,
			paymentTerm: "NET 120",
			dueDate,
			numberLabel: "PO",
			numberLabelValue,
			remittanceInfo,
			supplierName,
			supplierAddress,
			billToName,
			billToAddress,
			notes,
			shippingTerm,
			shipByDate,
			buyerText: "Bill to",
			data,
			total,
			subTotal,
			deposit,
			receivedDeposit,
			shipToName,
			shipToAddress,
			shipToText,
			receivedDepositLabel,
		}
	},
	{
		displayText: "Sample HTC Purchase Order",
		documentOptions: {
			logoURL: process.env.PUBLIC_URL + "/frame-one-software-logo.png",
			title: "Purchase Order",
			date,
			paymentTerm: "NET 120",
			dueDate,
			numberLabel: "PO",
			numberLabelValue,
			remittanceInfo,
			supplierName,
			supplierAddress,
			billToName,
			billToAddress,
			notes,
			shippingTerm,
			shipByDate,
			warningText: "No Deposit for HTC Invoice",
			buyerText: "Bill to",
			data,
			subTotal,
			total,
			receivedDeposit,
			shipToName,
			shipToAddress,
			shipToText,
			receivedDepositLabel,
		}
	},
	{
		displayText: "Watermark Example",
		documentOptions: {
			logoURL: process.env.PUBLIC_URL + "/frame-one-software-logo.png",
			title: "Purchase Order",
			date,
			paymentTerm: "NET 120",
			dueDate,
			numberLabel: "PO",
			numberLabelValue,
			remittanceInfo,
			supplierName,
			supplierAddress,
			billToName,
			billToAddress,
			notes,
			shippingTerm,
			shipByDate,
			warningText: "No Deposit for HTC Invoice",
			buyerText: "Bill to",
			data,
			total,
			subTotal,
			waterMark: "Draft",
			deposit,
			shipToName,
			shipToAddress,
			shipToText,
			receivedDepositLabel,
		}
	},
	{
		displayText: "Lots of Data",
		documentOptions: {
			logoURL: process.env.PUBLIC_URL + "/harbor-trade-credit-logo.png",
			title: "Invoice",
			date,
			paymentTerm: "NET 120",
			dueDate,
			numberLabel: "Invoice",
			numberLabelValue,
			remittanceInfo,
			supplierName,
			supplierAddress,
			billToName,
			billToAddress,
			notes,
			shippingTerm,
			buyerText: "Bill to",
			data: lotsOfData,
			total: lotsOdDataTotal,
			subTotal: lotsOfDataSubTotal,
			deposit,
			shipToName,
			shipToAddress,
			shipToText,
			receivedDepositLabel,
		}
	},
];

const Debug: React.FC = () => {

	const [loading, setLoading] = useState<boolean>(false);
	const [markdown, setMarkdown] = useState<string>();

	// get docs
	useEffect(() => {
		axios.get(process.env.PUBLIC_URL + "/README.md")
			.then(res => {
				setMarkdown(res.data);
			})
			.catch(err => {

			})
	}, [])

	function generateButton(buttonOptions: IButtonOptions, index: number) {

		async function downloadPDF() {
			try {
				setLoading(true);
				const res = await axios.post(
					`${process.env.REACT_APP_PDF_SERVER_ADDRESS}/generate-htc-document`,
					buttonOptions.documentOptions,
					{responseType: "blob", headers: {api_key: process.env.REACT_APP_PDF_SERVER_SECRET}});
				fileDownload(res.data, buttonOptions.displayText + ".pdf");
			} catch (err) {
				alert("There was an error, please check the console.");
				console.log(err);
			}
			setLoading(false);
		}

		const params = new URLSearchParams({info: JSON.stringify(buttonOptions.documentOptions)});
		const url = `/invoice?${params.toString()}`;

		return (
			<React.Fragment key={index}>
				{index !== 0 && <hr/>}
				<CardText><h3>{buttonOptions.displayText}</h3></CardText>
				<FormGroup>
					<Label>URL</Label>
					<Input type="text" value={url} contentEditable={false}/>
					<FormText><Link to={url}>Go to URL</Link></FormText>
				</FormGroup>
				<ButtonGroup>
					<Button
						key={"button_" + index}
						color="primary"
						onClick={downloadPDF}
			 			disabled={loading}
					>
						Generate
					</Button>
					<Button color="primary" id={"toggler" + index}>
						See JSON
					</Button>
				</ButtonGroup>
				<UncontrolledCollapse toggler={"toggler" + index}>
					<br/>
					<pre>
						<code>
							{JSON.stringify(buttonOptions.documentOptions, null, 4)}
						</code>
					</pre>
				</UncontrolledCollapse>
			</React.Fragment>
		)
	}

	return (
		<Container>
			{
				markdown &&
				<Card className="mt-5">
					<CardHeader>README</CardHeader>
					<CardBody>
						<ReactMarkdown
							source={markdown}
						/>
					</CardBody>
				</Card>
			}
			<Card className="mt-5">
				<CardHeader>Asset Generation Debug Page</CardHeader>
				<CardBody>
					{buttons.map(generateButton)}
				</CardBody>
			</Card>
		</Container>
	)
};

export default Debug;
