import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import Invoice from "./pages/Invoice";
import Debug from "./pages/Debug";
import "./index.scss";

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/invoice" component={Invoice}/>
				<Route exact path="/debug" component={Debug}/>
			</Switch>
		</Router>
	);
}

export default App;
